import { SingleValueCardConfig, MergedTableConfig } from '../../../../../libs/config';
import { TranslateWithNamespaceValueFormatter, TranslateAndJoinWithNamespaceValueFormatter } from '../../../../formatters/TranslateValueFormatter';
import { Formatters } from '../../../../formatters';
import { AnyType } from '../../../../../types';
import { Widgetters } from '../../../../widgets';
import { ExplorationHistorySearch, ParameterType, SearchOperationType } from '../../../../../libs/filters/filters';
import { permissions } from '../../../../permissions';
import { giaConfigHelpers } from '../common/giaConfigHelpers';

const noopWidgetter = {
    widgetter: Widgetters.NoopWidgetter,
};
const nric = {
    chrooters: [
        [
            'persons',
            ...giaConfigHelpers.persons.selectors.firstPartyPersons,
            ...giaConfigHelpers.persons.selectors.driver,
            ...giaConfigHelpers.persons.selectors.nric,
        ],
    ],
    formatter: Formatters.LinksExplorationSearchFormatter('person'),
    widgetter: Widgetters.LinksExplorationSearchWidgetter,
};

const policyHistories: SingleValueCardConfig = {
    configType: 'SingleValueCard',
    context: [
        'policies',
        { name: '', selector: { path: ['creationChannel'], values: [{ Type: 'CommunicationChannel', Name: 'ElectronicDataInterchange' }] } },
    ],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            chrooters: [
                [
                    '__typename',
                ],
            ],
            formatter: TranslateWithNamespaceValueFormatter('history-exploration'),
            ...noopWidgetter,
        },
        {
            chrooters: [
                [
                    'effectiveDateOffset',
                ],
            ],
            formatter: Formatters.OffsetDateFormatter,
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['specializations', 'mapping_Policy_Insurer', 'insurer'],
            ],
            formatter: Formatters.EnumValuesFormatter,
            ...noopWidgetter,
        },
        {
            chrooters: [
                [
                    'referenceCode'],
            ],
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,
        },
        {
            chrooters: [
                [
                    'persons',
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_PolicyPerson', 'roleEnumIdentifier'],
                            values: ['PersonFunction'],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_PolicyPerson', 'roleEnumValueId'],
                            values: [1],
                        },
                    },
                    'specializations',
                    'mapping_Person_NationalIdentifier',
                    'nationalIdentifier',
                ],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('person'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,

        },
        {
            chrooters: [
                [
                    'objects',
                    'links',
                    'mapping_PolicyObject',
                    'effectiveDateOffset',
                ],
                [
                    'objects',
                    'links',
                    'mapping_PolicyObject',
                    'expirationDateOffset',
                ],
                [
                    'objects',
                    'links',
                    'mapping_PolicyObject',
                    'specializations',
                    'mapping_PolicyObject_Motor_TerminationReason',
                    'policyObjectTerminationReason',
                ],
            ],
            formatter: Formatters.ExplorationReferenceFormatter,
            ...noopWidgetter,
        },
    ],
};

const claimHistories: SingleValueCardConfig = {
    configType: 'SingleValueCard',
    context: ['claims',
        // we need data from Insurers side (NCD) side.
        // for now, we comment it
        { name: '', selector: { path: ['type'], values: [{ Type: 'ClaimType', Name: 'Insurance' }] } }],
    i18Namespace: 'history-exploration',
    transforms: [
        {

            chrooters: [
                [
                    '__typename',
                ],
                [
                    'type',
                ],
            ],
            formatter: TranslateAndJoinWithNamespaceValueFormatter('history-exploration', (val: AnyType) => `${val?.Type}-${val?.Name}`),
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['specializations', 'mapping_Claim_Event', 'eventDateOffset'],
            ],
            formatter: Formatters.OffsetDateFormatter,
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['specializations', 'mapping_Claim_Insurer', 'insurer'],
            ],
            formatter: Formatters.EnumValuesFormatter,
            ...noopWidgetter,
        },
        {
            chrooters: [
                [
                    'policy', 'referenceCode'],
            ],
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['policy', 'objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,
        },
        nric,
        {
            chrooters: [
                [
                    'referenceCode'],
            ],
            ...noopWidgetter,
        },
    ],
};

const accidentReportHistories: SingleValueCardConfig = {
    configType: 'SingleValueCard',
    context: [
        'claims',
        { name: '', selector: { path: ['type'], values: [{ Type: 'ClaimType', Name: 'ReportOnly' }] } },
        {
            name: '',
            selector: {
                path: ['status'],
                values: [
                    { Type: 'ClosingType', Name: 'Closed' },
                    { Type: 'ClosingType', Name: 'InProgress' }],
            },
        },
    ],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            chrooters: [
                [
                    '__typename',
                ],
                [
                    'type',
                ],
            ],
            formatter: TranslateAndJoinWithNamespaceValueFormatter('history-exploration', (val: AnyType) => `${val?.Type}-${val?.Name}`),
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['specializations', 'mapping_Claim_Event', 'eventDateOffset'],
            ],
            formatter: Formatters.OffsetDateFormatter,
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['specializations', 'mapping_Claim_Insurer', 'insurer'],
            ],
            formatter: Formatters.EnumValuesFormatter,
            ...noopWidgetter,
        },
        {
            chrooters: [
                [
                    'policy', 'referenceCode'],
            ],
            ...noopWidgetter,
        },
        {
            chrooters: [
                ['policy', 'objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,
        },
        nric,
        {
            chrooters: [
                ['id'],
                ['referenceCode'],
                ['paidAccess'],
                ['policy', 'objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
                ['isStatusUpdateAllowed'],

            ],
            formatter: Formatters.LinksBillableActionFormatter(
                ['UN_MATCH_AR', 'EXPLORATION_SEARCH'],
                [permissions.canPurchaseReportsInExploration, permissions.canSeeExploration],
                true,
            ),
            widgetter: Widgetters.LinksBillableActionWidgetter,
            permissions: permissions.canSeeMatchedReports,
        },

    ],
};

export const mergedHistories: MergedTableConfig = {
    configType: 'MergedTable',
    i18Namespace: 'history-exploration',
    header: [
        {
            i18n: 'type',
            filter: {

                field: { name: 'Type', id: ExplorationHistorySearch.HistoryByType },
                operation: {
                    type: SearchOperationType.EnumIdEqual,
                    name: 'EnumIdEqual',
                    parameters: [
                        { value: '', name: 'equalEnumId', type: ParameterType.Enum },
                    ],
                },
                widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('ExplorationHistoryType'),

            },
        },
        {
            i18n: 'date',
            filter: {

                field: { name: 'Date', id: ExplorationHistorySearch.HistoryByDate },
                operation: {
                    type: SearchOperationType.Between,
                    name: 'DateBetween',
                    parameters: [
                        { value: '', name: 'StartDate', type: ParameterType.DateTimeUtc },
                        { value: '', name: 'EndDate', type: ParameterType.DateTimeUtc },
                    ],
                },
                widgetter: Widgetters.Filter.DateFilterWidgetter,
            },
        },
        {
            i18n: 'insurer',
            filter: {
                field: { name: 'Insurer', id: ExplorationHistorySearch.HistoryByInsurer },
                operation: {
                    type: SearchOperationType.Match,
                    name: 'StringMatch',
                    parameters: [
                        { value: '', name: 'searchedText', type: ParameterType.String },
                    ],
                },
                widgetter: Widgetters.Filter.TextFilterWidgetter,
            },
        },
        {
            i18n: 'policy',
            filter: {

                field: { name: 'Policy', id: ExplorationHistorySearch.HistoryByPolicy },
                operation: {
                    type: SearchOperationType.Match,
                    name: 'StringMatch',
                    parameters: [
                        { value: '', name: 'searchedText', type: ParameterType.String },
                    ],
                },
                widgetter: Widgetters.Filter.TextFilterWidgetter,
            },
        },
        {
            i18n: 'vrn',
            filter: {
                field: { name: 'Vrn', id: ExplorationHistorySearch.HistoryByVrn },
                operation: {
                    type: SearchOperationType.Match,
                    name: 'StringMatch',
                    parameters: [
                        { value: '', name: 'searchedText', type: ParameterType.String },
                    ],
                },
                widgetter: Widgetters.Filter.TextFilterWidgetter,
            },
        },
        {
            i18n: 'nric',
            filter: {
                field: { name: 'Nric', id: ExplorationHistorySearch.HistoryByNric },
                operation: {
                    type: SearchOperationType.Match,
                    name: 'StringMatch',
                    parameters: [
                        { value: '', name: 'searchedText', type: ParameterType.String },
                    ],
                },
                widgetter: Widgetters.Filter.TextFilterWidgetter,
            },
        },
        {
            i18n: 'reference',
            filter: {

                field: { name: 'Reference', id: ExplorationHistorySearch.HistoryByReference },
                operation: {
                    type: SearchOperationType.Match,
                    name: 'StringMatch',
                    parameters: [
                        { value: '', name: 'searchedText', type: ParameterType.String },
                    ],
                },
                widgetter: Widgetters.Filter.TextFilterWidgetter,
            },
        },
    ],
    transforms: [
        policyHistories,
        claimHistories,
        accidentReportHistories,
    ],
};
