import moment, { unitOfTime } from 'moment-timezone';
import { Formatter } from '@shift/transform';
import { parseUtcDate, parseOffsetDate } from 'src/utils';
import {
    DateFormat, DateTimeDisplayFormat, DefaultTZUtcNow, DefaultTZNow,
} from 'src/constants';

const intervalDateFormat = (format: string, isUtc: boolean): Formatter<string[], { children: string }> => ({
    transform: (times) => {
        const [start, end] = times;
        const parseDate = isUtc ? parseUtcDate : parseOffsetDate;
        const startF = start && start.length !== 0 ? parseDate(start[0]).format(format) : '??';
        const endF = end && end.length !== 0 ? parseDate(end[0]).format(format) : '??';
        return {
            children: `${startF} - ${endF}`,
        };
    },
});

const fromDateTimeFormat = (format: string, isUtc: boolean): Formatter<string[], { children: string }> => ({
    transform: (times) => {
        const value = times[0][0];
        return {
            children: value && (isUtc ? parseUtcDate : parseOffsetDate)(value).format(format),
        };
    },
});

// Returns false if difference is negative
const fromTimeSpanFormat = (unit: unitOfTime.Diff, isUtc: boolean): Formatter<string[], { children: string | false }> => ({
    transform: (times) => {
        const value = times[0][0];
        const date = (isUtc ? parseUtcDate : parseOffsetDate)(value);
        const now = isUtc ? DefaultTZUtcNow : DefaultTZNow;

        if (!value || date < now) {
            return { children: false };
        }

        const diff = date.diff(now, unit);
        const stringValue = `${diff !== 0 ? diff : 1} ${unit}`;
        return {
            children: `${diff !== 0 ? '' : '< '}${stringValue}`,
        };
    },
});

const sinceTimeSpanFormat = (unit: unitOfTime.Diff, isUtc: boolean): Formatter<string[], { children: string | false }> => ({
    transform: (times) => {
        const value = times[0][0];
        const date = (isUtc ? parseUtcDate : parseOffsetDate)(value);
        if (!value || date > moment()) {
            return { children: false };
        }
        return {
            children: `${moment().diff(date, unit) + 1} ${unit}`,
        };
    },
});

export const DateTimeFormatters = {
    UtcDateFormatter: fromDateTimeFormat(DateFormat, true),
    OffsetDateFormatter: fromDateTimeFormat(DateFormat, false),
    UtcYearFormatter: fromDateTimeFormat('YYYY', true),
    OffsetYearFormatter: fromDateTimeFormat('YYYY', false),
    UtcDateTimeFormatter: fromDateTimeFormat(DateTimeDisplayFormat, true),
    OffsetDateTimeFormatter: fromDateTimeFormat(DateTimeDisplayFormat, false),
    UtcDateTimeSuffixFormatter: fromDateTimeFormat(`${DateTimeDisplayFormat} [(SGT)]`, true),
    OffsetDateTimeSuffixFormatter: fromDateTimeFormat(`${DateTimeDisplayFormat} [(SGT)]`, false),
    DaysLeftFormatter: fromTimeSpanFormat('days', true),
    DaysFromFormatter: sinceTimeSpanFormat('days', true),
    OffsetDateIntervalFormatter: intervalDateFormat('L', false),
};
