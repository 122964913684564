import { Path } from '@shift/transform';

import {
    ParameterType, SearchOperationType, ClaimFields, SearchOperationFormatSpecificType,
} from 'src/libs/filters';
import { permissions } from 'src/config/permissions';
import { Formatters, FormatterFactories } from 'src/config/formatters';
import { EnumValue } from 'src/types';
import { Widgetters } from 'src/config/widgets';
import { isDraftEnum, unknownObjectEnum } from 'src/constants';
import { parseOffsetDate } from 'src/utils';
import { AccidentReportConfig } from '../../types';
import { giaConfigHelpers } from './common/giaConfigHelpers';

const rootContext: Path = ['claim'];

const ownDriverContext: Path = [
    'persons',
    ...giaConfigHelpers.persons.selectors.firstPartyPersons,
    ...giaConfigHelpers.persons.selectors.driver,
];

const ownVehicleContext: Path = [
    'objects',
    { name: '', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumIdentifier'], values: ['PartySide'] } },
    { name: '', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [1] } },
];

const matchedReportsContext: Path = [
    'matchedReports',
    { name: '', selector: { path: ['type'], values: [{ Type: 'ClaimType', Name: 'ReportOnly' }] } },
];

const policyHolderContext: Path = [
    'persons',
    {
        name: '',
        selector: {
            path: ['links', 'mapping_PolicyPerson', 'roleEnumIdentifier'],
            values: ['PersonFunction'],
        },
    },
    {
        name: '',
        selector: {
            path: ['links', 'mapping_PolicyPerson', 'roleEnumValueId'],
            values: [1],
        },
    },
];

const translatorContext: Path = [
    {
        name: 'persons',
        selector: {
            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_PartySide', 'partySide'],
            values: [{ Type: 'PartySide', Name: 'Neutral' }],
        },
    },
    {
        name: '',
        selector:
        {
            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
            values: ['ClaimPersonFunction'],
        },
    },
    {
        name: '',
        selector:
        {
            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
            values: [17],
        },
    },
];

export const accidentReportsConfig: AccidentReportConfig = {
    list: {
        configType: 'Table',
        context: rootContext,
        i18Namespace: 'accident-reports',
        transforms: [
            {
                i18NId: 'claim-referenceCode',
                chrooters: [
                    ['id'],
                    ['referenceCode'],
                    ['status'], // added here to make sure it's fetched regardless of whether we show it
                ],
                formatter: Formatters.GoToDetailPageFormatter,
                widgetter: Widgetters.LinksWidgetter,
                filter: {
                    field: { id: ClaimFields.ReferenceCode },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'claim-version',
                chrooters: [
                    ['versionsCount'],
                ],
                filter: {
                    field: { id: ClaimFields.VersionsCount },
                    operation: {
                        type: SearchOperationType.Between,
                        name: 'IntergerEqual',
                        parameters: [
                            { value: '', name: 'min', type: ParameterType.IntegerParameter },
                            { value: '', name: 'max', type: ParameterType.IntegerParameter },
                        ],
                    },
                    widgetter: Widgetters.Filter.IntegerFilterWidgetter,
                },
            },
            {
                // TODO: replace by spe
                chrooters: [
                    ['submissionDate'],
                ],
                formatter: Formatters.UtcDateTimeFormatter,
                filter: {
                    field: { id: ClaimFields.SubmissionDate },
                    operation: {
                        type: SearchOperationType.Between,
                        name: 'DateBetween',
                        parameters: [
                            { value: '', name: 'StartDate', type: ParameterType.DateTimeUtc },
                            { value: '', name: 'EndDate', type: ParameterType.DateTimeUtc },
                        ],
                    },
                    widgetter: Widgetters.Filter.DateFilterWidgetter,

                },
            },
            {
                chrooters: [
                    ['firstSubmissionDate'],
                ],
                formatter: Formatters.UtcDateTimeFormatter,
                filter: {
                    field: { id: ClaimFields.FirstSubmissionDate },
                    operation: {
                        type: SearchOperationType.Between,
                        name: 'DateBetween',
                        parameters: [
                            { value: '', name: 'StartDate', type: ParameterType.DateTimeUtc },
                            { value: '', name: 'EndDate', type: ParameterType.DateTimeUtc },
                        ],
                    },
                    widgetter: Widgetters.Filter.DateFilterWidgetter,

                },
            },
            {
                i18NId: 'arc-name',
                chrooters: [
                    ['aRCName'],
                ],
                filter: {
                    field: { id: ClaimFields.ARCName },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                chrooters: [
                    ['accidentDate'],
                ],
                formatter: Formatters.OffsetDateFormatter,
                filter: {
                    field: { id: ClaimFields.AccidentDate },
                    operation: {
                        type: SearchOperationType.Between,
                        name: 'DateBetween',
                        parameters: [
                            { value: '', name: 'StartDate', type: ParameterType.DateTimeOffset },
                            { value: '', name: 'EndDate', type: ParameterType.DateTimeOffset },
                        ],
                    },
                    widgetter: Widgetters.Filter.DateFilterWidgetter,
                },
            },
            {
                i18NId: 'handling-insurer',
                chrooters: [
                    ['ownInsurer'],
                ],
                formatter: Formatters.EnumValuesFormatter,
                permissions: permissions.canSeeInsurerColumn,
                filter: {
                    field: { id: ClaimFields.OwnInsurer },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'EnumEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('InsurerCode'),
                },
                widgetter: Widgetters.MaxWidthTextWidgetter,
            },
            {
                chrooters: [
                    ['policyNo'],
                ],
                filter: {
                    field: { id: ClaimFields.PolicyNo },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'owner-name',
                chrooters: [
                    ['ownerName'],
                ],
                filter: {
                    field: { id: ClaimFields.OwnerName },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'owner-id',
                chrooters: [
                    ['ownerId'],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('person'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
                filter: {
                    field: { id: ClaimFields.OwnerId },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                chrooters: [
                    ['vehicleNo'],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
                filter: {
                    field: { id: ClaimFields.VehicleNo },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'claim-cause',
                chrooters: [
                    ['typeEnumIdentifier'],
                    ['typeEnumValueId'],
                ],
                formatter: Formatters.EnumValuesIdFormatter,
                filter: {
                    field: { id: ClaimFields.TypeOfAccident },
                    operation: {
                        type: SearchOperationType.EnumIdEqual,
                        name: 'EnumIdEqual',
                        parameters: [
                            { value: '', name: 'equalEnumId', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('AccidentType'),
                },
            },
            {
                i18NId: 'driver-name',
                chrooters: [
                    ['driverName'],
                ],
                filter: {
                    field: { id: ClaimFields.DriverName },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetterFactory('left'),
                },
            },
            {
                i18NId: 'driver-id',
                chrooters: [
                    ['driverId'],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('person'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
                filter: {
                    field: { id: ClaimFields.DriverId },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetterFactory('left'),
                },
            },
            {
                chrooters: [
                    ['status'],
                ],
                formatter: Formatters.EnumValuesFormatter,
                filter: {
                    field: { id: ClaimFields.Status },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'StringEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('ClosingType', 'left'),
                },
                permissions: permissions.canUpdateStatus,
            },
            {
                i18NId: 'draft',
                chrooters: [
                    ['status'],
                ],
                formatter: Formatters.ConditionalBooleanValueFormatterFactory<EnumValue>(
                    (val) => isDraftEnum(val),
                ),
                filter: {
                    field: {
                        id: ClaimFields.Status,
                        formatType: SearchOperationFormatSpecificType.ConditionalBooleanEquality,
                    },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'StringEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EqualConditionBooleanFilterWidgetterFactory('ClosingType.AtDraftStage', ParameterType.Enum),
                },
                permissions: permissions.canSeeDraftColumn,
            },
            {
                i18NId: 'claim-lateReport',
                chrooters: [
                    ['lateReport'],
                ],
                formatter: Formatters.BooleanValueFormatter,
                filter: {
                    field: { id: ClaimFields.LateReport },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'BooleanEqual',
                        parameters: [
                            { value: '', name: 'boolean', type: ParameterType.Boolean },
                        ],
                    },
                    widgetter: Widgetters.Filter.BooleanFilterWidgetterFactory('left'),
                },
            },
            {
                i18NId: 'claim-matchedReports',
                chrooters: [
                    [...matchedReportsContext, 'id'],
                    [...matchedReportsContext, 'referenceCode'],
                    [...matchedReportsContext, 'paidAccess'],
                    [...matchedReportsContext, 'policy', 'objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
                    [...matchedReportsContext, 'isStatusUpdateAllowed'],
                ],
                formatter: Formatters.LinksBillableActionFormatter(
                    ['UN_MATCH_AR', 'EXPLORATION_SEARCH'],
                    [permissions.canSeeMatchedReports, permissions.canSeeExploration],
                    true,
                ),
                widgetter: Widgetters.LinksBillableActionWidgetter,
                permissions: permissions.canSeeMatchedReports,
            },
        ],
    },

    ownDriver: {
        configType: 'SelectKeyValueCard',
        context: rootContext,
        i18Namespace: 'accident-report-details',
        header: {
            chrooters: [
                [
                    ...ownDriverContext, 'specializations', 'mapping_Person_Gears', 'name',
                ],
            ],
        },
        transforms: [
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
            },
            {
                i18NId: 'id-type',
                chrooters: [
                    [
                        ...ownDriverContext, 'specializations', 'mapping_Person_NationalIdentifier', 'identifier',
                    ],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'id-number',
                chrooters: [
                    [
                        ...ownDriverContext,
                        ...giaConfigHelpers.persons.selectors.anyIds,
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('person'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'specializations', 'mapping_Person_Individual', 'gender',
                    ],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'specializations', 'mapping_Person_Individual', 'dateOfBirth',
                    ],
                ],
                formatter: Formatters.OffsetDateFormatter,
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_DrivingLicense', 'startDate',
                    ],
                ],
                formatter: Formatters.OffsetDateFormatter,
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext,
                        'links',
                        'mapping_ClaimPerson',
                        'specializations',
                        'mapping_ClaimPerson_DrivingLicense',
                        'licenseTypeSourceName',
                    ],
                ],
                formatter: Formatters.DrivingLicenseClassEnumFormatter,
            },
            {
                i18NId: 'driving-license-validity',
                chrooters: [
                    [
                        ...ownDriverContext,
                        'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_DrivingLicense', 'licenseValidity',
                    ],
                    [
                        ...ownDriverContext,
                        'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_DrivingLicense', 'licenseValiditySourceName',
                    ],

                ],
                formatter: Formatters.DrivingLicenseValidityFormatter,
            },
            {
                i18NId: 'driving-experience',
                chrooters: [
                    [
                        ...ownDriverContext, 'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_DrivingLicense', 'startDate',
                    ],
                    [
                        'specializations', 'mapping_Claim_Event', 'eventDateOffset',
                    ],
                    [
                        'modifyDateUtc',
                    ],
                ],
                formatter: {
                    transform: (params: string[][]) => {
                        const [[licenseDate], [eventDate], [modifyDate]] = params;
                        const licenseMoment = parseOffsetDate(licenseDate);
                        const eventMoment = parseOffsetDate(eventDate ?? modifyDate);
                        let nbYears = eventMoment.year() - licenseMoment.year();
                        let nbMonths = eventMoment.month() - licenseMoment.month();
                        let result = '';
                        const monthLabel = `Month${Math.abs(nbMonths) > 1 ? 's' : ''}`;
                        const yearLabel = `Year${nbYears > 1 ? 's' : ''}`;

                        if (nbMonths < 0) {
                            nbYears -= 1;
                            nbMonths += 12;
                        }

                        if (nbYears > 0) {
                            if (nbMonths > 0) {
                                result = `${nbYears} ${yearLabel} and ${nbMonths} ${monthLabel}`;
                            } else {
                                result = `${nbYears} ${yearLabel}`;
                            }
                        } else {
                            result = `${nbMonths} ${monthLabel}`;
                        }

                        return { children: result };
                    },
                },
            },
            {
                i18NId: 'main-phone',
                chrooters: [
                    [
                        ...ownDriverContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Primary' }],
                            },
                        },
                        'countryCode',
                    ],
                    [
                        ...ownDriverContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Primary' }],
                            },
                        },
                        'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                i18NId: 'alt-phone-type',
                chrooters: [
                    // Hack: in order to display as many types as numbers, we need to chroot into the numbers themselves.
                    // Otherwise an empty type would just be ignored.
                    [
                        ...ownDriverContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'number',
                    ],
                    [
                        ...ownDriverContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'phoneNumberType',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory({
                    transform: (values: any) => {
                        const types = values[1];
                        if (!types || !types[0]) {
                            return { children: 'N/A' };
                        }
                        return Formatters.EnumValuesFormatter.transform([types as EnumValue[]]);
                    },
                }),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
            {
                i18NId: 'alt-phone',
                chrooters: [
                    [
                        ...ownDriverContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'countryCode',
                    ],
                    [
                        ...ownDriverContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'number',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.PhoneNumberFormatter),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'mails', 'address',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'addresses', 'fullAddress',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'addresses', 'complement',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        ...ownDriverContext, 'addresses', 'postCode',
                    ],
                ],
            },
            {
                i18NId: 'driver-occupation-outdoor',
                chrooters: [
                    [
                        ...ownDriverContext,
                        'specializations',
                        'mapping_Person_Individual',
                        'isOccupationOutdoor',
                    ],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'driver-owner-relation',
                chrooters: [
                    [
                        'policy',
                        ...policyHolderContext,
                        'id',
                    ],
                    [
                        ...ownDriverContext,
                        'id',
                    ],
                    [
                        ...ownDriverContext,
                        'personToPersonsByFirstPerson',
                        'roleEnumIdentifier',
                    ],
                    [
                        ...ownDriverContext,
                        'personToPersonsByFirstPerson',
                        'roleEnumValueId',
                    ],
                    [
                        ...ownDriverContext,
                        'personToPersonsByFirstPerson',
                        'specializations',
                        'mapping_PersonToPerson_RelationDetails',
                        'relationDescription',
                    ],
                ],
                formatter: {
                    transform: (values: any[][]) => {
                        const ownerIds = values[0];
                        const driverIds = values[1];
                        const identifiers = values[2];
                        const ids = values[3];
                        const descriptions = values[4] as string[];

                        if (ownerIds && ownerIds.length && driverIds && driverIds.length === ownerIds.length) {
                            if (ownerIds.every((id, idx) => driverIds[idx] === id)) {
                                return Formatters.EnumValuesIdFormatter.transform([['PersonRelation'], [11]]);
                            }
                        }

                        if (descriptions && descriptions.length) {
                            return { children: descriptions[0] };
                        }

                        return Formatters.EnumValuesIdFormatter.transform([identifiers, ids]);
                    },
                },
                widgetter: Widgetters.CardValueWidgetter,
            },
            {
                i18NId: 'is-own-other-vehicle',
                chrooters: [
                    [
                        ...ownDriverContext,
                        'links',
                        'mapping_ClaimPerson',
                        'specializations',
                        'mapping_ClaimPerson_Motor_VehicleOwner',
                        'ownAnyVehicle',
                    ],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'other-driver-vehicle-number',
                chrooters: [
                    [
                        ...ownDriverContext,
                        'objects',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ObjectToPerson', 'roleEnumIdentifier'],
                                values: ['PersonToObjectRole'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ObjectToPerson', 'roleEnumValueId'],
                                values: [1],
                            },
                        },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.SingleValueFormatter),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
            {
                i18NId: 'other-driver-vehicle-insurer',
                chrooters: [
                    [
                        ...ownDriverContext,
                        'objects',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ObjectToPerson', 'roleEnumIdentifier'],
                                values: ['PersonToObjectRole'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ObjectToPerson', 'roleEnumValueId'],
                                values: [1],
                            },
                        },
                        'policies',
                        'specializations',
                        'mapping_Policy_Insurer',
                        'insurer',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.EnumValuesFormatter),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
        ],
    },
    ownPolicy: {
        configType: 'KeyValueCard',
        context: rootContext.concat(['policy']),
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                chrooters: [
                    ['specializations', 'mapping_Policy_Insurer', 'insurer'],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                chrooters: [
                    ['referenceCode'],
                ],
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Policy_Motor', 'type'],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                chrooters: [
                    [
                        ...policyHolderContext,
                        'specializations',
                        'mapping_Person_Gears',
                        'name',
                    ],
                ],
            },
            {
                i18NId: 'policyholder-id',
                chrooters: [
                    [
                        ...policyHolderContext,
                        'specializations',
                        'mapping_Person_NationalIdentifier',
                        'nationalIdentifier',
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('person'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                i18NId: 'policyholder-id-type',
                chrooters: [
                    [
                        ...policyHolderContext,
                        'specializations',
                        'mapping_Person_NationalIdentifier',
                        'identifier',
                    ],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'main-phone',
                chrooters: [
                    [
                        ...policyHolderContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Primary' }],
                            },
                        },
                        'countryCode',
                    ],
                    [
                        ...policyHolderContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Primary' }],
                            },
                        },
                        'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                chrooters: [
                    [
                        ...policyHolderContext,
                        'mails',
                        'address',
                    ],
                ],
            },
            {
                i18NId: 'alt-phone-type',
                chrooters: [
                    // Hack: in order to display as many types as numbers, we need to chroot into the numbers themselves.
                    // Otherwise an empty type would just be ignored.
                    [
                        ...policyHolderContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'number',
                    ],
                    [
                        ...policyHolderContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'phoneNumberType',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory({
                    transform: (values: any) => {
                        const types = values[1];
                        if (!types || !types[0]) {
                            return { children: 'N/A' };
                        }
                        return Formatters.EnumValuesFormatter.transform([types as EnumValue[]]);
                    },
                }),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
            {
                i18NId: 'alt-phone',
                chrooters: [
                    [
                        ...policyHolderContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'countryCode',
                    ],
                    [
                        ...policyHolderContext,
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Secondary' }],
                            },
                        },
                        'number',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.PhoneNumberFormatter),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
        ],
    },
    ownVehicle: {
        configType: 'KeyValueCard',
        context: rootContext,
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number',
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                i18NId: 'vehicle-make',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'makeNotInEnum',
                    ],
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'make',
                    ],
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'makeDescription',
                    ],
                ],
                formatter: Formatters.VehicleMakeFormatter,
            },
            {
                i18NId: 'vehicle-model',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'model',
                    ],
                ],
                formatter: Formatters.VehicleModelFormatter,
            },
            {
                i18NId: 'vehicle-variant',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'model',
                    ],
                ],
                formatter: Formatters.VehicleVariantFormatter,
            },
            {
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'transmission',
                    ],

                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'engineCapacityValue',
                    ],

                ],
            },
            {
                i18NId: 'date-time-of-ownership',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'purchaseDateUtc',
                    ],

                ],
                formatter: Formatters.UtcDateTimeFormatter,

            },
            {
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'type',
                    ],

                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'vin',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'vIN',
                    ],

                ],
            },
            {
                i18NId: 'first-registration-date',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'firstRegistrationDateUtc',
                    ],

                ],
                formatter: Formatters.UtcDateFormatter,
            },
            {
                i18NId: 'vehicle-fuel',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'vehicleFuel',
                    ],
                    [
                        ...ownVehicleContext,
                        'specializations', 'mapping_Object_Motor_Vehicle', 'vehicleFuelSourceName',
                    ],

                ],
                formatter: Formatters.VehicleFuelFormatter,
            },
            {
                i18NId: 'vehicle-usage-purpose',
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'links', 'mapping_ClaimObject', 'specializations', 'mapping_ClaimObject_Motor_InvolvedVehicle', 'usagePurpose',
                    ],
                ],
                formatter: Formatters.BindedNameEnumValueFormatterFactory('VehiclePurpose'),
            },
            {
                chrooters: [
                    [
                        ...ownVehicleContext,
                        'links', 'mapping_ClaimObject', 'specializations', 'mapping_ClaimObject_Motor_InvolvedVehicle', 'numOccupants',
                    ],
                ],
            },
            {
                i18NId: 'passenger-names',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_PartySide', 'partySide'],
                                values: [{ Type: 'PartySide', Name: 'FirstParty' }],
                            },
                        },

                    ],
                    [
                        'persons',
                        'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier',
                    ],
                    [
                        'persons',
                        'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId',
                    ],
                    [
                        'persons',
                        'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.PassengerFormatter, true),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
            {
                i18NId: 'passenger-genders',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_PartySide', 'partySide'],
                                values: [{ Type: 'PartySide', Name: 'FirstParty' }],
                            },
                        },

                    ],
                    [
                        'persons',
                        'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier',
                    ],
                    [
                        'persons',
                        'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId',
                    ],
                    [
                        'persons',
                        'specializations', 'mapping_Person_Individual', 'gender',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.GenderFormatter, true),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
        ],
    },
    thirdParty: {
        configType: 'SelectKeyValueCard',
        context: rootContext.concat([
            'objects',
            { name: '', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumIdentifier'], values: ['PartySide'] } },
            { name: '', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [3] } },
        ]),
        i18Namespace: 'accident-report-details',
        header: {
            chrooters: [
                [
                    'specializations',
                ],
            ],
            formatter: Formatters.VehicleNumberSelectFormatter({
                prefix: 'accident-report-details:tp',
            }),
        },
        transforms: [
            {
                i18NId: 'Vehicle-registration-number',
                chrooters: [
                    [
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'type',
                    ],
                    [
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        {
                            name: '',
                            selector: { path: ['type'], values: [{ Type: 'RegistrationType', Name: 'Foreign' }] },
                        },
                        'number',
                    ],
                    [
                        'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number',
                    ],
                ],
                formatter: Formatters.MultipleFormatters([
                    Formatters.TernaryConditionFormatter({
                        conditionFct: (type: EnumValue) => type?.Name === 'Foreign',
                    }),
                    Formatters.LinksExplorationSearchFormatter('vehicle'),
                ]),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                i18NId: 'is-foreign-vehicle',
                chrooters: [
                    [
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        {
                            name: '',
                            selector: { path: ['type'], values: [{ Type: 'RegistrationType', Name: 'Foreign' }] },
                        },
                        'type',
                    ],
                ],
                formatter: Formatters.BooleanValueFallbackNoFormatter,
            },
            {
                i18NId: 'vehicle-make',
                chrooters: [
                    [
                        'specializations', 'mapping_Object_Motor_Vehicle', 'makeNotInEnum',
                    ],
                    [
                        'specializations', 'mapping_Object_Motor_Vehicle', 'make',
                    ],
                    [
                        'specializations', 'mapping_Object_Motor_Vehicle', 'makeDescription',
                    ],
                ],
                formatter: Formatters.VehicleMakeFormatter,
            },
            {
                i18NId: 'vehicle-model',
                chrooters: [
                    [
                        'specializations', 'mapping_Object_Motor_Vehicle', 'model',
                    ],
                ],
                formatter: Formatters.VehicleModelFormatter,
            },
            {
                i18NId: 'color',
                chrooters: [
                    [
                        'specializations', 'mapping_Object_Motor_Vehicle', 'color',
                    ],

                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'vehicle-type',
                chrooters: [
                    [
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'type',
                    ],
                    [
                        'specializations',
                        {
                            name: '',
                            selector: {
                                path: ['mapping_Object_Motor_VehicleRegistration', 'type'],
                                values: [{ Type: 'RegistrationType', Name: 'Foreign' }],
                            },
                        },
                        'mapping_Object_Motor_Vehicle',
                        'type',
                    ],
                    [
                        'specializations', 'mapping_Object_Motor_Vehicle', 'type',
                    ],

                ],
                formatter: Formatters.MultipleFormatters([
                    Formatters.TernaryConditionFormatter({
                        conditionFct: (type: EnumValue) => type?.Name === 'Foreign',
                    }),
                    FormatterFactories.DefaultValueFormatterFactory(unknownObjectEnum),
                    Formatters.EnumValuesFormatter,
                ]),
            },
            {
                chrooters: [
                    [
                        'policies', 'specializations', 'mapping_Policy_Insurer', 'insurer',
                    ],

                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'object-damage-description',
                chrooters: [
                    [
                        'specializations', 'mapping_Object_FreeText', 'description',
                    ],
                ],
            },
            {
                i18NId: 'damage-description',
                chrooters: [
                    [
                        'damages',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_DamageTarget', 'roleEnumIdentifier'],
                                values: ['PropertyDamageCategory'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_DamageTarget', 'roleEnumValueId'],
                                values: [1],
                            },
                        },
                        'specializations',
                        'mapping_Damage_FreeText',
                        'freeTextDescription',
                    ],
                ],
            },
            {
                i18NId: 'driver-name',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
            },
            {
                i18NId: 'driver-id-type',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'specializations', 'mapping_Person_NationalIdentifier', 'identifier',
                    ],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'driver-id',
                chrooters: [
                    [
                        'persons',
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        ...giaConfigHelpers.persons.selectors.driverOfVehicle,
                        ...giaConfigHelpers.persons.selectors.anyIds,
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('person'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                i18NId: 'driver-phone',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'phoneNumbers', 'countryCode',
                    ],
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'phoneNumbers', 'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                i18NId: 'driver-address',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'addresses', 'fullAddress',
                    ],
                ],
            },
            {
                i18NId: 'driver-address-complement',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'addresses', 'complement',
                    ],
                ],
            },
            {
                i18NId: 'driver-postcode',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'addresses', 'postCode',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        'links',
                        'mapping_ClaimObject',
                        'specializations',
                        'mapping_ClaimObject_Motor_InvolvedVehicle',
                        'numOccupants',
                    ],
                ],
            },
            {
                i18NId: 'passenger-names',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [4] } },
                        'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.SingleValueFormatter),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
            {
                i18NId: 'passenger-genders',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [4] } },
                        'specializations', 'mapping_Person_Individual', 'gender',
                    ],
                ],
                formatter: Formatters.MultiFormatterFactory(Formatters.EnumValuesFormatter),
                widgetter: Widgetters.MultiWidgetterFactory(Widgetters.CardValueWidgetter),
            },
        ],
    },
    injured: {
        configType: 'SelectKeyValueCard',
        context: rootContext.concat([
            'persons',
            {
                name: '',
                selector: {
                    path: ['damages', 'links', 'mapping_DamageTarget', 'roleEnumIdentifier'],
                    values: ['PropertyDamageCategory'],
                },
            },
            {
                name: '',
                selector: {
                    path: ['damages', 'links', 'mapping_DamageTarget', 'roleEnumValueId'],
                    values: [28],
                },
            },
        ]),
        i18Namespace: 'accident-report-details',
        header: {
            chrooters: [
                [
                    'specializations', 'mapping_Person_Gears', 'name',
                ],
            ],
        },
        transforms: [
            {
                chrooters: [
                    [
                        'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        'specializations', 'mapping_Person_Individual', 'gender',
                    ],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'phone-number',
                chrooters: [
                    [
                        'phoneNumbers', 'countryCode',
                    ],
                    [
                        'phoneNumbers', 'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                i18NId: 'injured-age',
                chrooters: [
                    [
                        'specializations', 'mapping_Person_Individual', 'dateOfBirth',
                    ],
                    [
                        'claims',
                        'specializations',
                        'mapping_Claim_Event',
                        'eventDateOffset',
                    ],
                    [
                        'claims',
                        'modifyDateUtc',
                    ],
                ],
                formatter: {
                    transform: (params: string[][]) => {
                        const [[dobDate], [eventDate], [modifyDate]] = params;

                        const dobMoment = parseOffsetDate(dobDate);
                        const eventMoment = parseOffsetDate(eventDate ?? modifyDate);

                        const diff = dobMoment.isValid() && eventMoment.isValid()
                            ? `${eventMoment.diff(dobMoment, 'years')} year(s) old`
                            : '';

                        return { children: diff };
                    },
                },
            },
            {
                chrooters: [
                    [
                        'addresses', 'fullAddress',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        'addresses', 'complement',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        'addresses', 'postCode',
                    ],
                ],
            },
            {
                i18NId: 'injuries-sustained',
                chrooters: [
                    [
                        'damages', 'specializations', 'mapping_Damage_FreeText', 'freeTextDescription',
                    ],
                ],
            },
            {
                i18NId: 'seat-belt-worn',
                chrooters: [
                    [
                        'links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Motor_Passenger', 'seatbeltWorn',
                    ],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'injured-vehicle',
                chrooters: [
                    [
                        'objects',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'],
                                values: [4],
                            },
                        },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                i18NId: 'injured-took-ambulance',
                chrooters: [
                    [
                        'damages',
                        'links',
                        'mapping_DamageTarget',
                        'specializations',
                        'mapping_DamageTarget_BodilyInjury_Ambulance',
                        'victimUsedAmbulance',
                    ],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
        ],
    },
    witness: {
        configType: 'SelectKeyValueCard',
        context: rootContext.concat([
            'persons',
            {
                name: '',
                selector: {
                    path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_PartySide', 'partySide'],
                    values: [{ Type: 'PartySide', Name: 'Neutral' }],
                },
            },
            {
                name: '',
                selector: {
                    path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                    values: ['ClaimPersonFunction'],
                },
            },
            {
                name: '',
                selector: {
                    path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                    values: [5],
                },
            },
        ]),
        i18Namespace: 'accident-report-details',
        header: {
            chrooters: [
                [
                    'specializations', 'mapping_Person_Gears', 'name',
                ],
            ],
        },
        transforms: [
            {
                chrooters: [
                    [
                        'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
            },
            {
                i18NId: 'phone-number',
                chrooters: [
                    [
                        'phoneNumbers', 'countryCode',
                    ],
                    [
                        'phoneNumbers', 'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                chrooters: [
                    [
                        'mails', 'address',
                    ],
                ],
            },
        ],
    },
    passenger: {
        configType: 'SelectKeyValueCard',
        context: rootContext,
        i18Namespace: 'accident-report-details',
        header: {
            chrooters: [
                [
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                            values: ['MotorClaimPersonFunction'],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                            values: [4],
                        },
                    },
                    'persons', 'name',
                ],
            ],
        },
        transforms: [
            {
                chrooters: [
                    [
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                                values: [4],
                            },
                        },
                        'persons', 'name',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                                values: [4],
                            },
                        },
                        'persons', 'specializations', 'mapping_Person_Individual', 'gender',
                    ],
                ],
            },
            {
                chrooters: [
                    [
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                                values: [4],
                            },
                        },
                        'persons', 'claims', 'objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number',
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
        ],
    },

    accident: {
        configType: 'KeyValueCard',
        context: rootContext,
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                i18NId: 'who-claimant',
                chrooters: [
                    [
                        'persons',
                        'links',
                        'mapping_ClaimPerson',
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                                values: ['ClaimPersonFunction'],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                                values: [4],
                            },
                        },
                    ],
                ],
                formatter: Formatters.ClaimantEnumFormatter,
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Event', 'eventDateOffset'],
                ],
                formatter: Formatters.OffsetDateTimeSuffixFormatter,
            },
            {
                chrooters: [
                    ['versions', 'submissionDate'],
                ],
                formatter: FormatterFactories.ElementAtFormatterFactory(0, Formatters.UtcDateTimeSuffixFormatter),
            },
            {
                i18NId: 'claim-lateReport',
                chrooters: [
                    ['specializations', 'mapping_Claim_Accident', 'isLateReported'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'claim-cause',
                chrooters: [
                    ['specializations', 'mapping_Claim_Cause', 'enumIdentifier'],
                    ['specializations', 'mapping_Claim_Cause', 'enumValueId'],
                ],
                formatter: Formatters.EnumValuesIdFormatter,
            },
            {
                i18NId: 'claim-underOwnPolicy',
                chrooters: [
                    ['specializations', 'mapping_Claim_PurposeIndication', 'purpose'],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'claim-weatherCondition',
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_RoadAccident', 'weatherConditionDescription'],
                    ['specializations', 'mapping_Claim_Motor_RoadAccident', 'weatherCondition'],
                ],
                formatter: {
                    transform: (values) => {
                        const [[desc], enums] = values as [string[], EnumValue[]];
                        return desc ? { children: desc as string } : Formatters.EnumValuesFormatter.transform([enums]);
                    },
                },
            },
            {
                i18NId: 'claim-roadSurface',
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_RoadAccident', 'roadSurfaceDescription'],
                    ['specializations', 'mapping_Claim_Motor_RoadAccident', 'roadSurface'],
                ],
                formatter: {
                    transform: (values) => {
                        const [[desc], enums] = values as [string[], EnumValue[]];
                        return desc ? { children: desc as string } : Formatters.EnumValuesFormatter.transform([enums]);
                    },
                },
            },
            {
                i18NId: 'arc-name',
                chrooters: [
                    [
                        'provider',
                        { name: '', selector: { path: ['links', 'mapping_ClaimProvider', 'roleEnumIdentifier'], values: ['ProviderRole'] } },
                        { name: '', selector: { path: ['links', 'mapping_ClaimProvider', 'roleEnumValueId'], values: [10] } },
                        'person',
                        'specializations',
                        'mapping_Person_Gears',
                        'name',
                    ],
                ],
            },
            {
                i18NId: 'arc-uid',
                chrooters: [
                    [
                        'provider',
                        { name: '', selector: { path: ['links', 'mapping_ClaimProvider', 'roleEnumIdentifier'], values: ['ProviderRole'] } },
                        { name: '', selector: { path: ['links', 'mapping_ClaimProvider', 'roleEnumValueId'], values: [10] } },
                        'person',
                        ...giaConfigHelpers.persons.selectors.anyIds,
                    ],
                ],
            },
            {
                i18NId: 'arc-user-name',
                chrooters: [
                    [
                        'providerEntity',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ProviderEntityToClaim', 'roleEnumIdentifier'],
                                values: ['ProviderEntityToClaimRole'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ProviderEntityToClaim', 'roleEnumValueId'], values: [1] } },
                        'person',
                        'specializations',
                        'mapping_Person_Gears',
                        'name',
                    ],
                ],
            },
        ],
    },
    otherInformation: {
        configType: 'KeyValueCard',
        context: rootContext,
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'numberOfVehicles'],
                ],
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyInjuredPersons'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyInjuredPersonConveyedByAmbulance'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyWitness'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyUnknownPersonAssistance'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyPoliceReport'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                chrooters: [
                    [
                        'specializations', 'mapping_Claim_Motor_Checklist', 'anyOtherVehicleDamage',
                    ],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'any-foreign-vehicle',
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyForeignVehicle'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'translator-name',
                chrooters: [
                    [
                        ...translatorContext,
                        'specializations',
                        'mapping_Person_Gears',
                        'name',
                    ],
                ],
            },
            {
                i18NId: 'translator-id',
                chrooters: [
                    [
                        ...translatorContext,
                        'specializations',
                        'mapping_Person_NationalIdentifier',
                        'nationalIdentifier',
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('person'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                i18NId: 'translator-phone',
                chrooters: [
                    [
                        ...translatorContext,
                        'phoneNumbers',
                        'countryCode',
                    ],
                    [
                        ...translatorContext,
                        'phoneNumbers',
                        'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                i18NId: 'translator-email',
                chrooters: [
                    [
                        ...translatorContext, 'mails', 'address',
                    ],
                ],
            },
            {
                i18NId: 'original-language',
                chrooters: [
                    [
                        {
                            name: 'documents',
                            selector: {
                                path: ['specializations', 'document_Document_Classification', 'documentCategory'],
                                values: [{ Type: 'DocumentCategory', Name: 'Report' }],
                            },
                        },
                        'specializations',
                        {
                            name: 'mapping_Document_FreeText',
                            selector: { path: ['identifier'], values: [{ Type: 'DocumentFreeTextType', Name: 'Language' }] },
                        },
                        'freeTextDescription',
                    ],
                ],
            },
        ],
    },
    statement: {
        configType: 'SingleValueCard',
        context: rootContext,
        transforms: [
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Cause', 'detailedCircumstances'],
                ],
                formatter: Formatters.SingleValueFormatter,
                widgetter: Widgetters.TextWidgetter,
            },
        ],
    },

    fraudElements: {
        configType: 'Table',
        context: rootContext.concat(['fraud_elements']),
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                chrooters: [
                    ['score'],
                ],
            },
            {
                chrooters: [
                    ['link'],
                ],
            },
        ],
    },

    attachments: {
        configType: 'Table',
        context: rootContext.concat(['documents']),
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                i18NId: 'claim-documents-document',
                chrooters: [
                    ['id'],
                    ['specializations', 'mapping_Document_FreeText', 'identifier'],
                    ['specializations', 'mapping_Document_FreeText', 'freeTextDescription'],
                    ['documentName'],
                    ['specializations', 'document_Document_DestinationDiskStorageInformation', 'fileSize'],
                ],
                formatter: Formatters.ThumbnailFormatter,
                widgetter: Widgetters.ThumbnailWidgetter,
            },
            {
                chrooters: [
                    ['modifyDateUtc'],
                ],
                formatter: Formatters.UtcDateFormatter,
            },
        ],
    },
    recoveries: {
        configType: 'Table',
        context: rootContext.concat(['recoveries']),
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                i18NId: 'recovery-referenceCode',
                chrooters: [
                    ['id'],
                    ['referenceCode'],
                ],
                formatter: Formatters.GoToRecoveryPageFormatter,
                widgetter: Widgetters.LinksWidgetter,
            },
            {
                i18NId: 'officer-in-charge',
                chrooters: [['workflow', 'officer', 'identity', 'insurerEntity', 'person', 'specializations', 'mapping_Person_Gears', 'name']],
            },
            {
                i18NId: 'own-side',
                chrooters: [
                    ['links', 'mapping_RecoveryClaim', 'roleEnumValueId'],
                ],
                formatter: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    transform: (value: any) => {
                        const val = value && value.length > 0 && value[0][0];
                        let role = null;
                        if (val) {
                            role = val === 3 ? 'Third' : 'Receiving';
                        } else {
                            role = 'Initiating';
                        }
                        return {
                            children: `${role} Party`,
                        };
                    },
                },
            },
            {
                i18NId: 'other-party-insurer',
                chrooters: [
                    ['workflow', 'party'],
                    ['workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'workflow',
                        'claims',
                        'specializations',
                        'mapping_Claim_Insurer',
                        'insurer',
                    ]],
                formatter: Formatters.OtherPartyFormatterFactory(Formatters.EnumValuesFormatter),
            },
            {
                i18NId: 'other-party-vehicle-number',
                chrooters: [
                    ['workflow', 'party'],
                    ['workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'workflow',
                        'claims',
                        'objects',
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.OtherPartyFormatterFactory(Formatters.LinksExplorationSearchFormatter('vehicle')),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                chrooters: [
                    ['workflow', 'lastWorkflowStep', 'state'],
                ],
            },
            {
                i18NId: 'case-type',
                chrooters: [
                    ['accidentReports', 'links', 'mapping_RecoveryTarget', 'roleEnumIdentifier'],
                    ['accidentReports', 'links', 'mapping_RecoveryTarget', 'roleEnumValueId'],
                ],
                formatter: Formatters.EnumValuesIdFormatter,
            },
        ],
    },
    versions: {
        configType: 'Table',
        context: rootContext,
        i18Namespace: 'accident-report-versions',
        transforms: [
            {
                chrooters: [['versions', 'submissionDate']],
                formatter: Formatters.UtcDateTimeSuffixFormatter,
            },
            {
                i18NId: 'claim_versions_pdf_link',
                chrooters: [
                    ['id'],
                    ['referenceCode'],
                    ['versions', 'submissionDate'],
                    ['versions', 'number'],
                ],
                formatter: Formatters.PDFLinkFormatter,
                widgetter: Widgetters.DownloadWidgetter,
            },
        ],
    },
    matchedReports: {
        configType: 'Table',
        context: [...rootContext, ...matchedReportsContext],
        i18Namespace: 'accident-report-matched-reports',
        transforms: [
            {
                i18NId: 'claim-referenceCode',
                chrooters: [
                    ['id'],
                    ['referenceCode'],
                    ['status'], // This is just added here to figure out if the report is missing in a later stage (not used in widgetter)
                ],
                formatter: Formatters.GoToDetailPageFormatter,
                widgetter: Widgetters.LinksWidgetter,
            },
            {
                chrooters: [['specializations', 'mapping_Claim_Insurer', 'insurer']],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                chrooters: [
                    [
                        { name: 'objects', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumIdentifier'], values: ['PartySide'] } },
                        { name: '', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [1] } },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
        ],
    },
    initiateRecovery: {
        configType: 'KeyValueCard',
        context: rootContext,
        transforms: [
            {
                chrooters: [
                    ['id'],
                    ['importedDocuments', 'id'],
                    ['importedDocuments', 'category'],
                    ['importedDocuments', 'name'],
                    ['referenceCode'],
                ],
            },
            { chrooters: [['specializations', 'mapping_Claim_Insurer', 'insurer']] },
            { chrooters: [['policy', 'persons', 'specializations', 'mapping_Person_Gears', 'name']] },
            {
                chrooters: [
                    [
                        'objects',
                        { name: '', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumIdentifier'], values: ['PartySide'] } },
                        { name: '', selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [1] } },
                        'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number',
                    ],
                ],
            },
        ],
    },
    reportSubmitted: {
        configType: 'KeyValueCard',
        context: rootContext,
        transforms: [
            {
                chrooters: [
                    ['id'],
                    ['referenceCode'],
                    ['status'],
                    ['versions', 'submissionDate'],
                    ['versions', 'version'],
                    ['versions', 'number'],
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                                values: ['MotorClaimPersonFunction'],
                            },
                        },
                        { name: '', selector: { path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'], values: [3] } },
                        'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
            },
        ],
    },
    attachmentSummary: {
        configType: 'KeyValueCard',
        context: rootContext,
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyAccidentPhoto'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyAccidentVideo'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
        ],
    },
    policeAction: {
        configType: 'KeyValueCard',
        context: rootContext,
        i18Namespace: 'accident-report-details',
        transforms: [
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyPoliceReport'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_Person_Organization', 'orgType'],
                                values: [{ Type: 'OrgType', Name: 'Police' }],
                            },
                        },
                        'specializations',
                        'mapping_Person_Gears',
                        'name',
                    ],
                ],
            },
            {
                i18NId: 'phone-number',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_Person_Organization', 'orgType'],
                                values: [{ Type: 'OrgType', Name: 'Police' }],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['phoneNumbers', 'phoneNumberType'],
                                values: [{ Type: 'PhoneNumberType', Name: 'Phone' }],
                            },
                        },
                        'phoneNumbers',
                        'countryCode',
                    ],
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_Person_Organization', 'orgType'],
                                values: [{ Type: 'OrgType', Name: 'Police' }],
                            },
                        },
                        {
                            name: '',
                            selector: {
                                path: ['phoneNumbers', 'phoneNumberType'],
                                values: [{ Type: 'PhoneNumberType', Name: 'Phone' }],
                            },
                        },
                        'phoneNumbers',
                        'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_Person_Organization', 'orgType'],
                                values: [{ Type: 'OrgType', Name: 'Police' }],
                            },
                        },
                        'addresses',
                        'fullAddress',
                    ],
                ],
            },
            {
                i18NId: 'fax-number',
                chrooters: [
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_Person_Organization', 'orgType'],
                                values: [{ Type: 'OrgType', Name: 'Police' }],
                            },
                        },
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['phoneNumberType'],
                                values: [{ Type: 'PhoneNumberType', Name: 'Fax' }],
                            },
                        },
                        'countryCode',
                    ],
                    [
                        'persons',
                        {
                            name: '',
                            selector: {
                                path: ['specializations', 'mapping_Person_Organization', 'orgType'],
                                values: [{ Type: 'OrgType', Name: 'Police' }],
                            },
                        },
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['phoneNumberType'],
                                values: [{ Type: 'PhoneNumberType', Name: 'Fax' }],
                            },
                        },
                        'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
            },
            {
                chrooters: [
                    ['specializations', 'mapping_Claim_Motor_Checklist', 'anyProsecutionGiven'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'assessment-text',
                chrooters: [
                    [
                        'assessments',
                        'specializations',
                        {
                            name: 'mapping_Assessment_FreeText',
                            selector: {
                                path: ['identifier'],
                                values: [{ Type: 'AssessmentFreeTextType', Name: 'Prosecution' }],
                            },
                        },
                        'text',
                    ],
                ],
            },
        ],
    },
    referenceCode: {
        configType: 'Processed',
        context: ['claim'],
        fields: [
            ['referenceCode'],
            ['status'],
            ['versions', 'submissionDate'],
            ['versions', 'version'],
            ['versions', 'number'],
            ['isCommentAllowed'],
            ['isStatusUpdateAllowed'],
            ['isRecoveriesAccessAllowed'],
            ['isRecoveryCreationAllowed'],
        ],
    },
    comments: {
        configType: 'Processed',
        context: ['claim'],
        fields: [
            ['comments', 'content'],
            ['comments', 'createDateUtc'],
            ['comments', 'author', 'userName'],
        ],
    },
    accidentLocation: {
        configType: 'Processed',
        context: ['claim', 'addresses'],
        fields: [
            ['fullAddress'],
            ['country'],
            ['latitude'],
            ['longitude'],
            ['complement'],
        ],
    },
};
