import { NcdConfig } from '../../types/NcdConfig';
import { Formatters } from '../../../formatters';
import { Widgetters } from '../../../widgets';

export const ncdConfig: NcdConfig = {
    result: {
        configType: 'KeyValueCard',
        context: ['ncd'],
        i18Namespace: 'ncd',
        transforms: [
            {
                i18NId: 'renewal-ncd',
                chrooters: [
                    [
                        'policyObject',
                        'specializations',
                        'mapping_PolicyObject_NoClaimsDiscount',
                        {
                            name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'RenewalNcd' }] },
                        },
                        'coefficient',
                    ],
                    [
                        'policyObject',
                        'specializations',
                        'mapping_PolicyObject_NoClaimsDiscount',
                        { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                        'coefficient'],
                ],
                formatter: Formatters.NcdValuesFormatter,
                widgetter: Widgetters.TrajectoryIconWidget,
            },
            {
                i18NId: 'current-ncd',
                chrooters: [
                    [
                        'policyObject',
                        'specializations',
                        'mapping_PolicyObject_NoClaimsDiscount',
                        { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                        'coefficient'],
                ],
                widgetter: Widgetters.PercentageWidget,
            },
            {
                i18NId: 'accident-report-past-three-years',
                chrooters: [
                    [
                        'numAccidents',
                    ],
                ],
            },
            {
                i18NId: 'ncd-protection',
                chrooters: [
                    [
                        'policyObject',
                        'specializations',
                        'mapping_PolicyObject_NoClaimsDiscount',
                        { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                        'protection'],
                ],
                formatter: Formatters.BooleanValueFormatter,
            },
            {
                i18NId: 'cancellationDateOffset',
                chrooters: [
                    [
                        'policyObject',
                        {
                            name: '',
                            selector: {
                                path: ['specializations',
                                    'mapping_PolicyObject_Motor_TerminationReason',
                                    'policyObjectTerminationReason'],
                                values: [{ Type: 'PolicyObjectTerminationReason', Name: 'Cancelled' }],
                            },
                        },
                        'expirationDateOffset',
                    ],
                ],
                formatter: Formatters.OffsetDateFormatter,
            },
            {
                i18NId: 'expirationDateOffset',
                chrooters: [
                    [
                        'policyObject',
                        'expirationDateOffset',
                    ],
                ],
                formatter: Formatters.OffsetDateFormatter,
            },
        ],
    },
};
