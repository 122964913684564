import { InsurerEnquiryConfig } from '../../types/InsurerEnquiryConfig';
import { Formatters } from '../../../formatters';

export const insurerEnquiryConfig: InsurerEnquiryConfig = {
    result: {
        configType: 'KeyValueCard',
        context: ['unused'],
        i18Namespace: 'insurer-enquiry',
        transforms: [
            {
                i18NId: 'insurer',
                chrooters: [
                    ['insurer', 'insurerCode'],
                ],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                i18NId: 'policy-period',
                chrooters: [
                    ['policyStartDate'],
                    ['policyEndDate'],
                ],
                formatter: Formatters.OffsetDateIntervalFormatter,
            },
            {
                i18NId: 'requester',
                chrooters: [
                    ['user', 'arcEntity', 'person', 'specializations', 'mapping_Person_Gears', 'name'],
                    ['user', 'arcEntity', 'arc', 'person', 'specializations', 'mapping_Person_Gears', 'name'],
                ],
                formatter: Formatters.NameAndCompanyFormatter,
            },
            {
                i18NId: 'request-date',
                chrooters: [
                    ['requestDate'],
                ],
                formatter: Formatters.UtcDateTimeFormatter,
            },
        ],
    },
};
